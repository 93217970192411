@import "../../assets/css/var.scss";
.back-wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  .icon {
    font-size: 24px;
  }
  .back-text {
    font-size: 11px;
    color: #636363;
    margin-left: 8px;
  }
}