.container {
	width: 100%;
	height: 100%;
	background: #4164F0;
  .steed-title {
		font-size: 27px;
		letter-spacing: 12px;
		font-weight: 400;
		color: #FEFEFE;
		text-align: center;
		margin-bottom: 40px;
	}
	.copyright {
		position: absolute;
		width: 100%;
		bottom: 30px;
		.copyright-one {
			color: #FFFFFF;
			text-align: center;
		}
	}
}
.title {
	text-align: center;
	color: #fff;
	font-size: 26px;
	margin-bottom: 25px;
}
.content-main {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}	
.content {
	width: 750px;
	height: 400px;
	border: 12px solid rgba(150, 195, 255, .5);
	border-radius: 16px;
	.login-main {
		width: 733px;
		height: 382px;
		border-radius: 11px;
		margin: -3px 0 0 -3px;
		background: #4164F0;
		display: flex;
		overflow: hidden;
	  .login-img {
			width: 410px;
			height: 382px;
			flex-shrink: 0;
			position: relative;
			img {
				width: 390px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.Verification {
			display: flex;
			margin-top:  15px;
			justify-content: space-between;
		}
		.login-form-z {
			width: 323px;
			flex-shrink: 0;
			height: 382px;
			padding: 8px 23px;
			background: #fff;
			position: relative;
			.login-form {
				margin-top: 10px;
			}
			.server-list {
				.server-btn {
					display: flex;
					justify-content: space-evenly;
				}
				.server-main {
					overflow-y: scroll;
					height: 280px;
				}
				.server-title {
					padding-top: 10px;
					font-size: 11px;
					color: #282828;
					padding-bottom: 13px;
					border-bottom:1px solid #EEEEEE;
				}
				.status-yes {
					.server-left,.server-go {
						color: #4164F0;
					}
				}
				.status-no {
					.server-left, .server-go {
						color: #BEBEBE;
					}
				}
				.server-item {
					display: flex;
					cursor: pointer;
					justify-content: space-between;
					align-items: center;
					padding: 10px 0;
					border-bottom:1px solid #EEEEEE;
					.server-left {
						display: flex;
						align-items: center;
						.server-name {
							margin-left: 10px;
						}
					}
				}
			}
			.wx-page {
				.wx-title,{
					padding-top: 10px;
					font-size: 11px;
					color: #282828;
					padding-bottom: 13px;
					border-bottom:1px solid #EEEEEE;
				}
				.wx-qrcode {
					width: 134px;
					height: 134px;
					border: solid 1px #999999;
				  margin: 30px auto 0;
				}
				.wx-text {
					text-align: center;
					font-size: 10px;
					margin-top: 10px;
				}
			} 
			.action-s {
				color: #4164F0;
				text-align: center;
				position: absolute;
				cursor: pointer;
				width: 90%;
				font-size: 10px;
				user-select:none;
				outline: none;
				bottom: 20px;
			}
			.action-img {
				width: 43px;
				height: 43px;
				position: absolute;
				cursor: pointer;
				right: 10px;
				bottom: 10px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.forget-pass {
				color: #999999;
				font-size: 10px;
				float: right;
				margin-top: 10px;
				cursor: pointer;
			}
			.ant-input:placeholder-shown {
				font-size: 10px;
			}
			.ant-input {
				border-radius: 3px;
				height: 36px;
			}
			.ant-form-item {
				margin-bottom: 10px;
			}
			.ant-btn-primary {
				border-radius: 3px;
				height: 36px;
			}
			.has-error .ant-form-explain {
				font-size: 10px;
			}
			.ant-tabs-ink-bar {
				background: #282828;
			}
			.ant-tabs-nav {
				.ant-tabs-tab {
					font-size: 14px;
					margin-right: 23px;
					color: #959595;
				}
				.ant-tabs-tab-active {
					color: #282828;
				}
			} 
		}
	}
}
