.search-header {
	padding-left: 20px;
	background-color: #fff;
	min-height: 35px;
  	display: flex;
	align-items: center;
	.ant-form.searh-header-form {
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		width: 100%;
		.ant-input {
			border-radius: 12px;
			height: 24px;
		}
		.ant-form-item {
			.ant-form-item-control-input {
				height: 24px;
				.ant-select-selector {
					background: #f0f0f0;
					border-radius: 12px;
					font-size: 14px;
					height: 24px;
					min-width: 142px;
					color: #656565;
				}
				.ant-select-selection-search-input {
					height: 24px;
				}
				.ant-select-single .ant-select-selector .ant-select-selection-item {
					line-height: 24px;
				}
			}
			.ant-form-item-label > label {
				font-size: 14px;
				color: #282828;
			}
			.ant-btn {
				height: 24px;
				padding: 0 5px;
				border-radius: 12px;
				overflow: hidden;
				font-size: 14px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				span {
					display: inline;
					line-height: 1;
				}
				.action {
					position: relative;
					top: 2px;
				}
			}
		}
	}
}
