body {
	background: #F4F4F4;
}
/* 路由过渡效果 */
.fade-enter {
	opacity: 0;
	transform: translateX(-30px);
}

.fade-enter-active,
.fade-exit-active {
	opacity: 1;
	transition: all 500ms ease-out;
	transform: translateX(0);
}

.fade-exit {
	display: none;
	transform: translateX(30px);
}
$borderColor: #d8d8d8;
.app-sider {
	border-right: 1px solid $borderColor;
	overflow-y: auto;
	overflow-x: hidden;
}
.ant-menu-inline {
	border: none;
}
.logo {
	height: 36px;
	display: flex;
	align-items: center;
	color: #646464;
	justify-content: flex-end;
	color: initial;
	font-size: 21px;
	margin-right: 15px;
	background: transparent;
}
.top-header {
	background: #4164F0;
	border-bottom: 1px solid $borderColor;
	padding: 0 56px 0 39px;
	position: relative;
	.top-header-inner {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		.header-title {
			flex: 1;
			font-size: 20px;
			display: flex;
			align-items: center;
			.loginTitle {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				margin-left: 39px;
			}
		}
		.header-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 320px;
			.schoolName {
				color: #fff;
				margin-right: 10px;
				height: 30px;
				line-height: 30px;
				border-right: 2px solid #fff;
				padding-right: 10px;
			}
			.userList {
				display: flex;
				align-items: center;
				.userName {
					margin-left: 20px;
					color: #fff;
					margin-right: 10px;
				}
			}
		}
	}
	.drop-list {
		li {
			padding: 10px 15px;
		}
	}
}

.shadow-radius {
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1);
	min-height: calc(100vh - 61px);
}

.public-title {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid rgb(217, 217, 217);
	margin-bottom: 10px;
}

.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: inline;
	margin: 0;
	padding: 0;
	color: #1890ff;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}
.tags {
	display: flex;
	margin-bottom: 4px;
	.tags-scroll {
		position: relative;
		width: 100%;
		height: 31px;
	}
}
.tags-list {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	padding: 0;
	transition: all 0.2s ease-in-out 0s;
	width: 100%;
	li {
		list-style: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $borderColor;
		padding: 4px 22px;
		position: relative;
		margin-right: 4px;
		word-break: keep-all;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background: #b1b1b1;
			border-radius: 50%;
			left: 8px;
			top: 12px;
			position: absolute;
		}
		&:first-child {
			em,
			&:before {
				display: none;
			}
		}
		em {
			font-style: normal;
			font-size: 16px;
			margin-left: 4px;
			width: 14px;
			height: 14px;
			position: absolute;
			right: 4px;
			top: 2px;
		}
		.hide {
			display: none;
		}
	}
	.isActive {
		color: #fff;
		background: linear-gradient(to right, #2187e8, #1890ff);
		border-color: #1890ff;
		&:before {
			background: #fff;
		}
	}
}

.search-form {
	padding: 15px;
	.ant-form-item {
		display: flex;
	}
	.ant-form-item-control-wrapper {
		flex: 1;
	}
}
.serarch-btns {
	display: flex;
	.ant-form-item {
		margin-right: 10px;
	}
}
.breadCrumb .ant-breadcrumb{
	height: 35px;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	background-color: #fff;
	padding-left: 21px;
	border-bottom: 1px solid #e1e1e1;
	&:empty {
		display: none;
	}
}

.list {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	li {
		width: calc(100% / 8);
		height: 100px;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
		i {
			font-size: 28px;
			margin-bottom: 5px;
		}
		&:hover {
			background: #e4e4e4;
			transition: all 0.15s ease-in-out 0s;
			i {
				transform: scale(1.5);
				transition: all 0.15s ease-in-out 0s;
			}
		}
	}
}
.ant-menu-inline-collapsed {
	width: 50px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
	font-size: 21px;
}

.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
	padding: 0 13px !important;
}
.main-content-parent {
	flex: auto;
	position: relative;
	display: flex;
	overflow-x: hidden;
	overflow-y: auto;
	.main-content {
		flex-direction: column;
	}
}

.custom-layout {
	flex: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: calc(100vh - 60px);
	.custom-layout-header {
		min-height: 36px;
		background: #fff;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e1e1e1;
	}	
	.custom-layout-content {
		flex: 1;
		display: flex;
		position: relative;
		flex-direction: column;
		height: calc(100vh - 60px - 36px);
	}
}

@for $i from 1 to 100 {
	.font-#{$i} {
			font-size: $i + px;
	 }
	 .mr-#{$i} {
		 margin-right: $i + px;
	 }
}

.flex {
	display: flex;
}

.ellipsis {
		white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
	}

	#loading {
		position: fixed;
		width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(245, 245, 245, 0.6)
	}